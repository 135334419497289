/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    h3: "h3",
    code: "code",
    pre: "pre",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.robinwieruch.de/minimal-node-js-babel-setup"
  }, "Source from rw;eruch")), "\n", React.createElement(_components.h2, null, "What is this?"), "\n", React.createElement(_components.p, null, "This post is personal reference"), "\n", React.createElement(_components.h3, null, "TL:DR"), "\n", React.createElement(_components.p, null, "The other day I started building an APi first project with Node and express and I have become so accustom out of the box support for ES6 for the last few years using ", React.createElement(_components.a, {
    href: "https://reactjs.org/docs/create-a-new-react-app.html"
  }, "Create React APP"), ", or hoping into a ", React.createElement(_components.a, {
    href: "https://codesandbox.io/"
  }, "CodeSandBox"), " that when ", React.createElement(_components.code, null, "import express from 'express'"), " exploded I was a little taken aback...:blush:"), "\n", React.createElement(_components.p, null, "Naturally I Googled \"Nodejs Babel Config\" and the one I liked was ", React.createElement(_components.a, {
    href: "https://www.robinwieruch.de/minimal-node-js-babel-setup"
  }, "rw;eruch - Minimal Nodejs Babel Setup"), ". Being particularly lazy and also aware that I have configured a node project for ES6 a thousand times. I quickly scrolled to the few command I needed which are listed below."), "\n", React.createElement(_components.h2, null, "The Commands and Files"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm i --save-dev nodemon @babel/core @babel/node @babel/preset-env\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, ".babelrc")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"presets\": [\"@babel/preset-env\"]\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "package.json")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "...\n\"scripts: {\n  ...\n  \"dev\": \"nodemon --exec babel-node app.js\"\n  ...\n}\n....\n")), "\n", React.createElement(_components.h3, null, "Notes:"), "\n", React.createElement(_components.p, null, "In this particular project I ended up using webpack for my build due to the way ", React.createElement(_components.a, {
    href: "https://heroku.com/"
  }, "Heroku"), " handles deployment."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
